import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
} from '@angular/core';
import { IconComponent } from '@cca/ui';
import { CdkDatePipe } from '@cca-common/cdk';
import { TRANSLOCO_SCOPE } from '@jsverse/transloco';
import { NotificationViewModel } from '@cca-infra/notification-service/v1';

@Component({
  selector: 'cca-notification',
  standalone: true,
  imports: [IconComponent, CdkDatePipe],
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: TRANSLOCO_SCOPE,
      useValue: 'notifications',
    },
  ],
})
export class NotificationComponent {
  @HostBinding('class')
  classes = 'block border-cca-neutrals-300 rounded-xl bg-cca-neutrals-100';

  @Input({ required: true }) notification!: NotificationViewModel;

  @Output() closeNotification = new EventEmitter<NotificationViewModel>();

  @Output() openNotification = new EventEmitter<NotificationViewModel>();

  onClose(event: MouseEvent) {
    event.stopPropagation();
    this.closeNotification.emit(this.notification);
  }

  onClick() {
    this.openNotification.emit();
  }
}
